<template>
  <div>
    <div id="paypal-button-container"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.loadPayPalScript();
  },
  methods: {
    loadPayPalScript() {
      const script = document.createElement('script');
      script.src = "https://www.paypal.com/sdk/js?client-id=AW2eYMJNnOheK8TAvbnzY8iJalIsg78_uBvg_2y97OE9hbz4JNBCCRqXC_SJn85IYbt3IKtEWggpKa_a&currency=USD"; // Replace with your client ID
      script.async = true;
      script.onload = this.initializePayPalButton;
      document.body.appendChild(script);
    },
    initializePayPalButton() {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: '10.00' // Replace with the amount you want to charge
              }
            }]
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then(() => {
            // alert('Transaction completed by ' + details.payer.name.given_name);
            // this.$router.push('https://localhost/');
            window.location.href = 'https://quicpay.io';
            // window.open('https://quicpay.io');

          });
        },
        onError: (err) => {
          console.error(err);
          alert('An error occurred during the transaction');
        }
      }).render('#paypal-button-container');
    }
  }
};
</script>

<style>
/* Add any styles you need */
</style>
